<template>
  <!--
    Takes a test picture of a Photopoint Installation
  -->
  <div>
    <LoadingPlaceholder v-if="loading" />    
    <template v-else>
      <img
        v-if="image"
        loading="lazy"
        :src="image"
        class="img-fluid"
      >
    </template>
    <span
      v-if="loading && takePhotoActive"
      id="loadingMessage"
    >Taking Photo..
    </span>
    <span
      v-if="loading && getPhotoActive"
      id="loadingMessage"
    >Downloading Photo..
    </span>
  </div>
</template>
  
<script>
export default {
  name: 'PhotoPointTestPicture',
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      takePhotoActive: false,
      getPhotoActive: false,
      image: null,
    }
  },
  methods: {
    getTestPhoto () {
      this.getPhotoActive = true;
      this.axios.get(`/Photopoint/GetPhoto/${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.base64Data == null) {
            return;
          }
          this.image = 'data:image/jpg;base64,' + response.data.base64Data;
        })
         .finally(() => {
          this.getPhotoActive = false;
          this.loading = false;
        });
    },
    takeTestPhoto () {
      this.loading = true;
      this.takePhotoActive = true;
      this.axios.post(`/Photopoint/TakePhoto?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == 200) {
            this.getTestPhoto();
          }
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.takePhotoActive = false;
        });
    }
  }
}
</script>

<style scoped>
#loadingMessage{
 font-size:15px;
 text-align:center;
 display:block;
}
</style>
